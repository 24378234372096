import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import { withPrefix } from 'gatsby';
import ContactSVG from '../components/backgroundSVGS/ContactSVG';

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preferredMethod: 'email'
        };
    }

    handle_PreferredMethodChange = (event, value) => {
        this.setState({
            ...this.state,
            preferredMethod: value
        });
    };

    render() {
        return (
            <Layout>
                <Helmet
                    title="Contact | Element Studio - Cheltenham Web Design &amp; Development"
                    meta={[
                        {
                            name: 'description',
                            content:
                                'Looking for a team to tackle your next web project? Get in touch with Element Studio to discuss how we can help.'
                        }
                    ]}
                />
                <header className="hero">
                    <div className="hero__inner">
                        <h1 className="type-backdrop">
                            <ContactSVG />
                        </h1>
                        <div className="hero-content">
                            <h2 className="type-title type-uppercase _has-subhead">Get in touch</h2>
                            <span className="type-metadata">Who you gonna call?</span>
                        </div>
                    </div>
                    <svg className="signpost-center" viewBox="0 0 8 74" width="8" height="74">
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </header>

                <section className="wrapper-chunky">
                    <form
                        name="contact-full"
                        className="container-mini contact_form"
                        method="POST"
                        action="/success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                    >
                        <input type="hidden" name="form-name" value="contact-full" />
                        <input type="hidden" name="bot-field" />
                        <div className="island-large text-align--center">
                            <h2 className="type-title type-uppercase _has-subhead">
                                Leave us a note<span className="type-highlight">,</span>
                            </h2>
                            <span className="type-metadata">
                                we'll get back to you<span className="type-highlight">!</span>
                            </span>
                        </div>
                        <blockquote>
                            <div className="contact_form_section">
                                <label className="contact_form_label">
                                    <span className="_is_visually-hidden">Name:</span>
                                    <span aria-hidden="true">My name is </span>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="eg. John Smith"
                                        size="15"
                                        required="required"
                                        className="text_input-inline" // TODO: Add Error class _has-error
                                    />
                                </label>
                            </div>
                            <div className="contact_form_section">
                                <span aria-hidden="true"> and it would be grand if you got </span>
                            </div>
                            <div className="contact_form_section">
                                <label className="contact_form_label">
                                    <span className="_is_visually-hidden">Preferred contact method:</span>
                                    <span aria-hidden="true">in touch with me via </span>
                                    <select
                                        aria-controls="contact-methods"
                                        id="select-method"
                                        name="method"
                                        className="select_input-inline" // TODO: Add Error class _has-error
                                        onChange={(event) => {
                                            this.handle_PreferredMethodChange(event, event.target.value);
                                        }}
                                    >
                                        <option value="email">Email</option>
                                        <option value="telephone">Telephone</option>
                                    </select>
                                </label>
                            </div>
                            <div className="contact_form_section" role="region" id="contact-methods" aria-live="polite">
                                <span aria-hidden="true"> on </span>
                                <label
                                    id="input-email"
                                    className={
                                        this.state.preferredMethod && this.state.preferredMethod === 'email'
                                            ? 'contact_form_label'
                                            : 'contact_form_label _is_visually-hidden'
                                    }
                                >
                                    <span className="_is_visually-hidden">Email Address:</span>
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="me@example.com"
                                        size="15"
                                        required={this.state.preferredMethod === 'email' ? true : false}
                                        className="text_input-inline" // TODO: Add Error class _has-error
                                    />
                                </label>
                                <label
                                    id="input-telephone"
                                    className={
                                        this.state.preferredMethod && this.state.preferredMethod === 'telephone'
                                            ? 'contact_form_label'
                                            : 'contact_form_label _is_visually-hidden'
                                    }
                                >
                                    <span className="_is_visually-hidden">Telephone Number:</span>
                                    <input
                                        name="telephone"
                                        type="tel"
                                        placeholder="01234 567890"
                                        size="15"
                                        required={this.state.preferredMethod === 'telephone' ? true : false}
                                        className="text_input-inline" // TODO: Add Error class _has-error
                                    />
                                </label>
                            </div>
                        </blockquote>
                        <div className="form_controls">
                            <button type="submit" className="btn-alpha contact_form_cta">
                                Send it!
                            </button>
                        </div>
                    </form>
                </section>

                <section className="wrapper-chunky bg-white">
                    <div className="container-narrow">
                        <div className="grid-x grid-margin-x small-only-rhythm">
                            <div className="cell small-12 medium-4">
                                <h4 className="type-small-heading">
                                    HQ<span className="type-highlight">&nbsp;//</span>
                                </h4>
                                <a
                                    href="https://www.google.com/maps/place/Element+Studio/@51.8969685,-2.0769041,15z/data=!4m2!3m1!1s0x0:0xf1de554285b621b3?sa=X&ved=2ahUKEwifrK3e563uAhUkWhUIHb5KBlgQ_BIwC3oECBwQBQ"
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                    title="View address on map"
                                >
                                    <address className="type-address">
                                        <svg width="20" height="20" viewBox="0 0 48 48" className="address-icon">
                                            <use xlinkHref="#icon-pin" />
                                        </svg>
                                        16 Imperial Square,
                                        <br />
                                        Cheltenham,
                                        <br />
                                        Gloucestershire.
                                        <br />
                                        GL50 1QZ
                                    </address>
                                </a>

                            </div>
                            <div className="contact_region cell small-12 medium-8 large-7 large-offset-1 text-align--right relative">
                                <img src={withPrefix('images/contact-map.svg')} alt="A map of the south west of England" />
                                <div className="contact_region_details">
                                    <a
                                        href="https://www.google.co.uk/maps/place/Element+Studio/@51.9004902,-2.0815793,17z/data=!4m5!3m4!1s0x48711b93cb074297:0xf1de554285b621b3!8m2!3d51.9004869!4d-2.0793906"
                                        className="type-label type-textlink"
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        title="View full map"
                                    >
                                        View full map
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default ContactPage;
